import React from "react"
import { graphql, Link } from "gatsby"
import {
  GatsbyImage,
  getImage,
  getSrc,
  ImageDataLike,
} from "gatsby-plugin-image"
import Container from "@material-ui/core/Container"
import moment from "moment"
import styled from "styled-components"

import SEO from "../components/Seo"
import Layout from "../components/Layout"
import { Article as ArticleType } from "../ts/interfaces"
import { Dividor } from "../components/ui/line"
import Icon from "../components/ui/Icon"
import SimpleContent from "../components/sections/SimpleContent"
import { LoadCampaignAffiliateData } from "../utils/campaignAffiliateData"

const StyledContainer = styled(Container)`
  padding: 0 10rem;
  @media (max-width: 900px) {
    padding: 0 2rem;
  }
`

const RelatedArticlesContainer = styled(Container)`
  padding-left: 24px;
  padding-right: 24px;
`

const BlogHeading = styled.h1`
  font-size: 2rem;
  line-height: 2.2rem;
  font-weight: 700;
  margin: 0;
`

const FeaturedHeading = styled.h2`
  margin-left: 12px;
`

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
`

const ArticleCategory = styled.h6`
  position: relative;
  margin-bottom: 32px;
  &::after {
    content: "";
    position: absolute;
    background: var(--color-gradient);
    bottom: -10px;
    left: 0;
    height: 6px;
    width: 100%;
    border-radius: 12px;
  }
`

// const PublishedDate = styled.p`
//   margin-top: 16px;
//   margin-bottom: 0;
//   font-size: 14px;
//   font-family: var(--font-family-header);
//   color: var(--color-grey-3);
// `

const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
`

const AuthorAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`

const AuthorName = styled.p`
  font-size: 14px;
  font-family: var(--font-family-header);
  font-weight: bold;
  color: var(--color-black);
`

// const ArticleImageContainer = styled.div`
//   display: flex;
//   margin: 32px auto;
//   width: 90%;
//   object-fit: cover;
//   object-position: center center;
//   border-radius: 32px;
// `

const ArticleImageContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 55%;
  }
`

const StyledDivider = styled(Dividor)`
  margin: 32px 0;
`

const FeaturedArticlesWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
`

const ArticleCard = styled(Link)`
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 4px 8px rgba(27, 31, 34, 0.15);
  width: 100%;
  font-family: var(--font-family-header);
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: initial;
  box-sizing: border-box;
  flex-basis: calc(33% - 24px);
  margin: 12px;
  &:hover,
  :visited {
    text-decoration: none;
    color: initial;
  }

  @media (max-width: 900px) {
    flex-basis: calc(50% - 24px);
  }

  @media (max-width: 670px) {
    flex-basis: calc(100% - 24px);
  }
`

const ImageWrapper = styled.div`
  height: 220px;
  width: 100%;
`
const ArticleCardImage = styled.div<{ imageUrl: string }>`
  background-image: ${({ imageUrl }) => imageUrl && `url("${imageUrl}")`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

const CardContent = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

const CardHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AuthorCardWrapper = styled.div`
  display: flex;
  align-items: center;
`

const AuthorCardName = styled.p`
  font-size: 14px;
  color: var(--color-grey-3);
`

const FeaturedHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SeeMore = styled.div`
  font-size: 14px;
  font-family: var(--font-family-header);
  font-weight: bold;
  font-size: 14px;
  color: var(--color-grey-3);
  text-transform: uppercase;

  & > span {
    padding-right: 6px;
  }
`

const Article = ({
  data: {
    strapi: { article, articles },
  },
}: {
  data: {
    strapi: {
      article: ArticleType
      articles: ArticleType[]
    }
  }
}) => {
  LoadCampaignAffiliateData()
  const { seo } = article
  const articleImage = getImage(
    (article.image?.imageFile as unknown) as ImageDataLike
  )

  return (
    <Layout>
      <SEO
        title={article.title}
        metaTitle={seo.metaTitle}
        description={seo.metaDescription}
        image={
          seo.metaImage &&
          getSrc(
            seo.metaImage?.imageFile?.childImageSharp
              ?.gatsbyImageData as ImageDataLike
          )
        }
      />
      <StyledContainer>
        <TopSection>
          <ArticleCategory>
            {article?.articleTag?.tagName.toUpperCase()}
          </ArticleCategory>
          <BlogHeading>{article.title}</BlogHeading>
          {/* <PublishedDate>
            {moment(new Date(article.published_at)).format("MMMM Do YYYY")}
          </PublishedDate> */}
          <AuthorWrapper>
            <AuthorAvatar
              src={article?.author?.avatar?.url}
              alt={"author avatar"}
            />
            <AuthorName>
              {article?.author?.firstName} {article?.author?.lastName}
            </AuthorName>
          </AuthorWrapper>
        </TopSection>
      </StyledContainer>
      {/* <ArticleImage
        src={article?.image?.url}
        alt={article?.image?.alternativeText}
      /> */}
      {articleImage && (
        <ArticleImageContainer>
          <GatsbyImage
            image={articleImage}
            alt="Featured Image"
            style={{
              // maxHeight: "500px",
              margin: "32px auto",
              borderRadius: "32px",
            }}
            // imgStyle={{
            //   borderRadius: "32px",
            // }}
            // objectFit="scale-down"
            // objectFit="contain"
            objectFit="cover"
            // objectPosition={"center center"}
          />
        </ArticleImageContainer>
      )}
      <StyledContainer>
        <SimpleContent {...article.content} />
      </StyledContainer>
      {article && article.articleDisclaimer && (
        <StyledContainer>
          <p>
            <strong>{article.articleDisclaimer.title}</strong>
            <br />
            <em>{article.articleDisclaimer.content}</em>
          </p>
        </StyledContainer>
      )}
      {!!(articles && articles.length) && (
        <>
          <StyledDivider />
          <RelatedArticlesContainer>
            <FeaturedHeadingContainer>
              <FeaturedHeading>Featured</FeaturedHeading>
              <Link to="/blog">
                <SeeMore>
                  <span>See more posts</span>
                  <Icon iconType="Solid" iconName="arrow-right" />
                </SeeMore>
              </Link>
            </FeaturedHeadingContainer>
            <FeaturedArticlesWrapper>
              {articles
                .sort(
                  (a, b) =>
                    new Date(a.published_at).getTime() -
                    new Date(b.published_at).getTime()
                )
                .map((article, index: number) => (
                  <ArticleCard
                    to={`/blog/${article?.slug}`}
                    key={`article--${index}`}
                  >
                    <ImageWrapper>
                      <ArticleCardImage imageUrl={article?.image?.url} />
                    </ImageWrapper>
                    <CardContent>
                      <CardHeading>
                        <AuthorCardWrapper>
                          <AuthorAvatar
                            src={article?.author?.avatar?.url}
                            alt={"author avatar"}
                          />
                          <AuthorCardName>
                            {article?.author?.firstName}{" "}
                            {article?.author?.lastName}
                          </AuthorCardName>
                        </AuthorCardWrapper>
                        <Icon iconType="Solid" iconName="arrow-right" />
                      </CardHeading>
                      {article.title}
                    </CardContent>
                  </ArticleCard>
                ))}
            </FeaturedArticlesWrapper>
          </RelatedArticlesContainer>
        </>
      )}
    </Layout>
  )
}

export default Article

export const ArticlePageQuery = graphql`
  query ArticleById($id: ID!, $tag: String!) {
    strapi {
      article(id: $id) {
        id
        published_at
        title
        content {
          id
          content
          align
          maxWidth
          largeFormat
          inlineMedia {
            referenceName
            rounded
            align
            width
            youtubeId
            youtubeMobileId
            image {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
            mobileImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
          }
          buttons {
            button {
              id
              title
              type
              link {
                __typename
                ... on STRAPI_ComponentNavigationMainNav {
                  navItemPage {
                    id
                    slug
                  }
                  navItemLandingPage {
                    id
                    slug
                  }
                  navItemName
                }
                ... on STRAPI_ComponentNavigationProduct {
                  id
                  addToCart
                  product {
                    id
                    stripeId
                    stripeTestId
                    slug
                  }
                  linkText
                }
                ... on STRAPI_ComponentNavigationHyperlink {
                  url
                }
                ... on STRAPI_ComponentZonesQuiz {
                  id
                  quizId
                }
              }
            }
          }
          core {
            anchorTag
            title
            titleColor
            subtitle
            subtitleColor
            backgroundColor
            animate
            marginTop
            marginBottom
            underlineTitle
            smallSubTitle
            titleAlign
            divider
            backgroundImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
          }
          featuredImage {
            url
            alternativeText
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FULL_WIDTH
                  quality: 70
                )
              }
            }
          }
          featuredVideo {
            youtubeId
            youtubeMobileId
          }
        }
        image {
          url
          alternativeText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FULL_WIDTH
                quality: 80
              )
            }
          }
        }
        author {
          id
          firstName
          lastName
          avatar {
            url
          }
        }
        articleTag {
          tagName
        }
        articleDisclaimer {
          title
          content
        }
        seo {
          id
          metaTitle
          metaDescription
          metaImage {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FIXED
                  quality: 80
                  width: 1200
                )
              }
            }
          }
        }
      }
      articles(
        where: { articleTag: { tagName: $tag }, previewOnly: false, id_ne: $id }
        limit: 3
        sort: "published_at:desc"
      ) {
        id
        published_at
        title
        content {
          id
          content
          align
          maxWidth
          largeFormat
          inlineMedia {
            referenceName
            rounded
            align
            width
            youtubeId
            youtubeMobileId
            image {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
            mobileImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
          }
          buttons {
            button {
              id
              title
              type
              link {
                __typename
                ... on STRAPI_ComponentNavigationMainNav {
                  navItemPage {
                    id
                    slug
                  }
                  navItemLandingPage {
                    id
                    slug
                  }
                  navItemName
                }
                ... on STRAPI_ComponentNavigationProduct {
                  id
                  addToCart
                  product {
                    id
                    stripeId
                    stripeTestId
                    slug
                  }
                  linkText
                }
                ... on STRAPI_ComponentNavigationHyperlink {
                  url
                }
                ... on STRAPI_ComponentZonesQuiz {
                  id
                  quizId
                }
              }
            }
          }
          core {
            anchorTag
            title
            titleColor
            subtitle
            subtitleColor
            backgroundColor
            animate
            marginTop
            marginBottom
            underlineTitle
            smallSubTitle
            titleAlign
            divider
            backgroundImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
          }
          featuredImage {
            url
            alternativeText
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FULL_WIDTH
                  quality: 70
                )
              }
            }
          }
          featuredVideo {
            youtubeId
            youtubeMobileId
          }
        }
        slug
        image {
          url
          alternativeText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FULL_WIDTH
                quality: 80
              )
            }
          }
        }
        author {
          id
          firstName
          lastName
          avatar {
            url
          }
        }
        articleTag {
          tagName
        }
        seo {
          id
          metaTitle
          metaDescription
          metaImage {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FIXED
                  quality: 80
                  width: 1200
                )
              }
            }
          }
        }
      }
    }
  }
`
